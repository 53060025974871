<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>账号设置</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin-bottom:10px">
            <el-button type="primary" size="small" @click="handleAdd">添加账号</el-button>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column width="280" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="keywords" size="mini" @change="handleSearch(scope)" placeholder="输入关键字搜索"/>
                    </template>
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" @click="handlePassword(scope.$index, scope.row)">重置密码</el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.username}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="mini" type="danger" slot="reference">删除</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="30%">
            <el-form ref="form" :model="form" label-width="100px" style="padding-right: 30px">
                <el-form-item label="用户名">
                    <el-input v-model="form.username" :readonly="!edit_username" :disabled="!edit_username"></el-input>
                </el-form-item>
                <el-form-item label="密码" v-if="edit_password">
                    <el-input v-model="form.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" v-if="edit_phone">
                    <el-input v-model="form.realname"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" v-if="edit_phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="角色" v-if="edit_phone">
                    <el-select v-model="form.role" placeholder="请选择">
                        <el-option v-for="item in roles" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>


<script>
    import roles from '@/data/roles'

    export default {
        data() {
            return {
                loading: false,
                roles: roles.roles,
                form: {
                    username: '',
                    password: '',
                    phone: '',
                    realname: '',
                    role: '',
                },
                table: {
                    columns: [
                        {title: '用户名', field: 'username', width: '200'},
                        {title: '角色', field: 'role', width: '200'},
                        {title: '真实姓名', field: 'realname', width: '200'},
                        {title: '联系电话', field: 'phone', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                keywords: '',
                dialogVisible: false,
                edit_username: true,
                edit_password: true,
                edit_phone: true,
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/auth/?page=${this.table.page}&pageSize=${this.table.pageSize}&username=${this.keywords}`)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.data
                    this.table.total = resp.data.data.total
                }
                this.loading = false
            },
            async onSubmit() {
                this.loading = true
                let resp
                if (this.form.id) {
                    resp = await this.$http.put(`/auth/${this.form.id}`, this.form)
                } else {
                    resp = await this.$http.post('/auth/', this.form)
                }
                if (resp.data.code != "200") {
                    this.$message.error(resp.data.msg)
                    this.loading = false
                } else {
                    this.getData()
                    this.dialogVisible = false
                }
            },
            handleAdd() {
                this.form = {username: '', password: '', phone: ''}
                this.edit_username = true
                this.edit_password = true
                this.edit_phone = true
                this.dialogVisible = true
            },
            handleEdit(idx, row) {
                this.form = row
                this.edit_username = false
                this.edit_password = false
                this.edit_phone = true
                this.dialogVisible = true
            },
            handlePassword(idx, row) {
                this.form = row
                this.edit_username = false
                this.edit_password = true
                this.edit_phone = false
                this.dialogVisible = true
            },
            async handleDelete(idx, row) {
                this.loading = true
                const resp = await this.$http.delete(`/auth/${row.id}`)
                console.log(resp)
                this.getData()
            },
            handleSearch() {
                this.table.page = 1
                this.getData()
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
