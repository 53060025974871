export default {
    roles: [
        {name: '系统管理员', value: '管理员'},
        {name: '分院管理员', value: '分院管理员'},
        {name: '磁共振', value: '磁共振'},
        {name: '门诊医生', value: '门诊医生'},
        {name: '健康管家', value: '健康管家'},
        {name: '预约中心', value: '预约中心'},
        {name: '企划', value: '企划'},
    ]
}
